.info-bugs {
  display: inline-block;
  width: 100%;
  padding: 3px $small-spacing;
  position: absolute;
}

.bug-new, .bug-img-18, .bug-b, .bug-cb, .bug-fw, 
.bug-holo, .bug-hs, .bug-j, .bug-m, .bug-mb, 
.bug-mc, .bug-p, .bug-pk
{ display: inline-block; background: url('https://loftus.s3-us-west-1.amazonaws.com/assets/bugs.png') no-repeat; overflow: hidden; text-indent: -9999px; text-align: left; }
.bug-new { background-position: -0px -0px; width: 25px; height: 15px; }
.bug-img-18 { background-position: -0px -15px; width: 21px; height: 15px; }
.bug-b { background-position: -0px -30px; width: 21px; height: 15px; }
.bug-cb { background-position: -0px -45px; width: 21px; height: 15px; }
.bug-fw { background-position: -0px -60px; width: 21px; height: 15px; }
.bug-holo { background-position: -0px -75px; width: 21px; height: 15px; }
.bug-hs { background-position: -0px -90px; width: 21px; height: 15px; }
.bug-j { background-position: -0px -105px; width: 21px; height: 15px; }
.bug-m { background-position: -0px -120px; width: 21px; height: 15px; }
.bug-mb { background-position: -0px -135px; width: 21px; height: 15px; }
.bug-mc { background-position: -0px -150px; width: 21px; height: 15px; }
.bug-p { background-position: -0px -165px; width: 21px; height: 15px; }
.bug-pk { background-position: -0px -180px; width: 21px; height: 15px; }