.ember-notify-default.ember-notify-cn {
  bottom: 110px;
}

.ember-notify {
  &.info {
    background-color: lighten($green,50%);
  }
  
  &.warning, &.error {
    background-color: lighten($red,30%);
  }
}