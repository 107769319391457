.list-articles {
  display: inline-block;
  width: 100%;
  
  ul {
    li {
      display: block;
      clear: both;

      .article-preview {
        float: left;
      }      


      .article-image {
        float: left;
        margin-left: 20px;
        margin-right: 20px;
        width: 200px;
      }
      .article-preview {
        width: calc(100% - 260px);
      }
      .article-title {
        font-size: 1.3em;
        font-weight: bold;
      }
      
    }
  }
  
  
}

.show-article {
  
  a {
    color: #9a58b5;
    border-bottom-width: 1px;
    border-bottom-color: #9a58b5;
    text-decoration: underline;
  }
  
  .article-items {
    @include grid-column(1 of 8);
    
    .collection-item {
      min-height: 320px;
      
      
      .image {
        margin-bottom: 0em;
      }
      
      .title {
        font-size: 1em;
        line-height: 1em;
        padding-top: 1em;
      }
      
      .sku {
        
      }
    }
    
  }
  
  .article {
    @include grid-column(5 of 8);
  }
  
  .title {
    display: block;
    font-size: 48px;
    line-height: 55px;
    font-weight: 400;
    padding-top: 40px;
    padding-bottom: 15px;    
  }
  
  .sub-title {
    display: block;
    font-size: 20px;
    line-height: 25px;
    font-weight: 400;
    padding-top: 20px;
    padding-bottom: 5px;    
    color: #56585c;
  }  
  
}