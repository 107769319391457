.item-results {
  margin-top: -12px;
  min-height: 200px;
  .ember-power-select-options[role="listbox"] {
    max-height: 25em;
  }
  
  li {
    padding: 5px;
    img {
      margin-right: $small-spacing;
    }
    font-size: 1.3em;
  }
  
}

.global-search {
  
  position: relative;
  .search-status {
    color: #666;
    position: absolute;
    right: 3px;
    top: 2px;
    z-index: 10;
  }
  
  .ember-power-select-typeahead-input.ember-power-select-search-input {
    border: none;
    box-shadow: none;
  }
}

.site-search {
  display: inline-block;
  form {
    min-height: 35px;
  }
  
  .autocomplete-results {
    z-index: 100;
    position: absolute;
    background-color: #fff;
    margin-top: -12px;
    padding: $base-spacing;
    border: 1px solid #ddd;
    border-radius: 3px;
    border-top: none;
    
  }
  
  .typeahead {
    > div {
      height: 35px;
    }

    .ember-power-select-typeahead-trigger {
      border-radius: 3px;
      min-height: 1em;
    }
    
    .ember-power-select-typeahead-input {
      margin-bottom: 0px;
    }

    input {
      background-color: $base-background-color;
      border: $base-border;
      border-radius: $base-border-radius;
      box-shadow: $form-box-shadow;
      box-sizing: border-box;
      padding: $base-spacing / 3;
      transition: border-color $base-duration $base-timing;
      width: 100%;
      min-height: 35px;
    
      &:hover {
        border-color: shade($base-border-color, 20%);
      }
    
      &:focus {
        border-color: $action-color;
        box-shadow: $form-box-shadow-focus;
        outline: none;
      }
    
      &:disabled {
        background-color: shade($base-background-color, 5%);
        cursor: not-allowed;
    
        &:hover {
          border: $base-border;
        }
      }
    
      &::placeholder {
        color: tint($base-font-color, 40%);
      }
    }

  }  
  
}

.item-search, .order-search, .customer-search, .purchase-order-search {
  display: inline-block;

  form {
    min-height: 51px;
  }
  
  .autocomplete-results {
    z-index: 100;
    position: absolute;
    background-color: #fff;
    margin-top: -12px;
    padding: $base-spacing;
    border: 1px solid #ddd;
    border-radius: 3px;
    border-top: none;
    
  }
  
  padding: $base-spacing;
  .typeahead {
    @include grid-column(4);
    > div {
      height: 50px;
    }
    input {
      background-color: $base-background-color;
      border: $base-border;
      border-radius: $base-border-radius;
      box-shadow: $form-box-shadow;
      box-sizing: border-box;
      padding: $base-spacing / 3;
      transition: border-color $base-duration $base-timing;
      width: 100%;
      min-height: 35px;
    
      &:hover {
        border-color: shade($base-border-color, 20%);
      }
    
      &:focus {
        border-color: $action-color;
        box-shadow: $form-box-shadow-focus;
        outline: none;
      }
    
      &:disabled {
        background-color: shade($base-background-color, 5%);
        cursor: not-allowed;
    
        &:hover {
          border: $base-border;
        }
      }
    
      &::placeholder {
        color: tint($base-font-color, 40%);
      }
    }

  }
  button {
    @include grid-column(2);
  }
}

.ember-power-select-typeahead-dropdown {
    li {
      display: flex;
    }
    
    span.company {
      @include grid-column(3 of 8);
    }
    
    span.locale {
      @include grid-column(3 of 8);
    }

    span.account {
      @include grid-column(2 of 8);
    }
    
    span.sku {
      @include grid-column(1 of 5);
    }
    
    span.title {
      @include grid-column(4 of 5);
    }
    
    span.available {
      @include grid-column(1 of 5);
    }

    span.discontinued {
      @include grid-column(1 of 5);
    }
    
    @include grid-media($grid-small) {
      font-size: 0.7em;
      span.sku {
        width: 80px;
        margin-left: 0px;
      }
      span.title {
        @include grid-column(12);
      }
    }    
    
}