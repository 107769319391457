.item-group {
  
  margin-bottom: $base-spacing * 2;
  display: inline-block;
  width: 100%;
  
    .item-group-info {
      @include grid-column(12);
      .icon {
        float: left;
        img {
          height: 30px;
        }
      }
      
      .group-finish {
        padding-left: 1em;
        float: left;
        color: $lb;
        font-size: 1em;
      }
      
      .group-name {
        float: left;
        padding-left: 0.5em;
        color: $lb;
        font-size: 1em;
      }
    }  
  
  
  @include grid-media($for-tablet-landscape-up) {
  
    .item-group-info {
      @include grid-column(12);
      .icon {
        padding-left: $base-spacing;
        float: left;
        img {
          height: 60px;
        }
      }
      
      .group-finish {
        padding-left: 1em;
        float: left;
        color: $lb;
        font-size: 3em;
      }
      
      .group-name {
        float: left;
        padding-left: 0.5em;
        color: $lb;
        font-size: 3em;
      }
    }
  }
}

.collection, .search-results {
  display: inline-block;
  padding-top: $base-spacing * 2;
  clear: both;
  width: 100%;
  
  @include grid-media($for-phone-only) {
    padding-top: $base-spacing;
  }  
  
.collection-items, .grid-items {
  > div {
    
    @include grid-column(6);
    
    @include grid-media($for-tablet-landscape-up) {
      @include grid-column(4);
    }

    @include grid-media($for-desktop-up) {
      @include grid-column(3);
    }

    @include grid-media($for-big-desktop-up) {
      @include grid-column(2);
    }    
    // @include grid-media($grid-xl) {
    //   @include grid-column(2);
    // }
    
  }
}
}

.search-facets {
  
  @include grid-column(6 of 6);

  @include grid-media($for-tablet-landscape-up) {
    
    @include grid-column(1 of 6);

    ul {
  
      span.filter-title {
        width: 100%;
        display: inline-block;
        font-weight: 700;
  
      }
  
      font-family: Graphik,Helvetica Neue,sans-serif;
      font-style: normal;
         
      
      width: 100%;
  
      
    
    }
    
    .display-toggle > input {
      display: none;
    }    
    
    ul.options {
      width: 100%;
      li {
        .doc-count {
          font-size: 0.8em;
        }
        font-weight: 400;

        label {
          color: rgb(64, 110, 181);
          font-weight: 100;
        }
      }
    }
  
  }

}

.filtered-search-results {
  display: inline-block;
  width: 100%;
  
  @include grid-media($for-tablet-landscape-up) {
    @include grid-column(5 of 6);
  }
  
  @include grid-media($for-phone-only) {
    padding-top: $base-spacing;
  }  
  
.collection-items, .grid-items {
  > div {
    
    .brand {
      color: #00a1dd;
      font-weight: 200;
      opacity: 0.8;
      font-size: 0.8em;
      
    }
    
    
    @include grid-column(6);
    
    @include grid-media($for-tablet-landscape-up) {
      @include grid-column(4);
    }

    @include grid-media($for-desktop-up) {
      @include grid-column(3);
    }

    @include grid-media($for-big-desktop-up) {
      @include grid-column(2 of 10);
    }    
    // @include grid-media($grid-xl) {
    //   @include grid-column(2);
    // }
    
  }
}  
}