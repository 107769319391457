.main {
  padding: $base-spacing;
  min-height: 860px;
  
  @include grid-media($for-phone-only) {
    padding: $small-spacing;
    min-height: 600px;
  }
  
}

button.shop {
    font-size: 3em;
    a {
      color: white;
    }
  @include grid-media($grid-small) {
    font-size: 2em;
  }  
}



.footer {
  
  @include grid-column(12);


  width: 100%;
  margin: auto;
  
  ul {
    @include grid-column(4);
    
    @include grid-media($for-phone-only) {
      @include grid-column(4 of 8);
      padding-top: $base-spacing;
      
      &.social {
        font-size: 0.7em;
        width: 100%;
        margin-left: 0px;
        
        li {
          @include grid-column(4 of 9);
        }
        
      }
      
    }
    
    @include grid-media($for-tablet-portrait-up) {    
    
    i {
      font-size: 2em;
      color: lighten($dark-gray,20%);
    }
    
    &.social {
      li {
        @include grid-column(1 of 2);
      }
    }
    
    }
    
  }
  a {
    color: #444;
  }
  
}

.covid-notice {
  text-align: center;
  background-color: #008a52;
  a {
    font-size: 1.2em;
    color: #fff;
    font-weight: bold;
  }
  width: 100%;
}


.header {
  
  // position: fixed;
  // top: 0px;
  display: inline-block;
  

  .logo {
    padding-top: 0.5em;
    @include grid-column(3);
    img {
      height: 35px;
    }

    @include grid-media($for-phone-only) {
      @include grid-column(4);
    }
    
    .joint-logo {
      display: none;
      @include grid-media($for-desktop-up) {
        display: inline-block;
      }      
    }
    
  }
  

  
  
  .sign-in {
    text-align: right;
    padding: $small-spacing;
    padding-right: $base-spacing;
  }
  
  .site-search {
    @include grid-column(6);
    @include grid-media($for-tablet-portrait-up) {
      @include grid-column(5);
    }
    color: white;
    font-size: 1.3em;
    padding-top: 0.25em;
  }
  
  .login {
    @include grid-column(6);
    padding-top: 0.5em;
    color: white;
    button {
      font-size: 1em;
    }
    font-size: 1em;
    a {
      color: white;
    }

  }
  
  .sign-out, .cart-icon {
    .sign-out-text {
      font-size: 0.5em;
    }
    font-size: 2em;
    text-align: right;
    @include grid-column(2);
  }
  
  .sign-out {
    @include grid-column(2);
  }
  
  @include grid-media($for-phone-only) {
    .sign-out, .cart-skus {
      display: none;
    }
  }
  
  
  
  width: 100%;

  .ember-power-select-typeahead-trigger {
    border-radius: 3px;
  }
  
  .ember-power-select-typeahead-input {
    margin-bottom: 0px;
  }
  
  button {
    font-size: 1.5em;
    background: none;
    padding: 0.6em;
    color: white;
  }  
  
//  @include fill-parent;
  display: inline-block;
  background: $white;
  
  div.menu-toggle {
    @include grid-column(1);
  }
  
  .header-options {
    // @include grid-media($smallest-screen) {
    //   display: none;
    // }
    // @include grid-media($small-screen) {
    //   display: none;
    // }
    
    // @include grid-media($medium-screen-up) {
    //   display: inline-block;
    //   font-size: 1.2em;
    // }
    
    a {
      color: white;
    }
  }
  
  nav {
    
    .icon {
      display: none;
    }
    
    @include grid-media($for-phone-only) {
      margin-left: -20px;
      width: calc(100%);
    }
    
    ul.pages {
      background-color: $blue;
      @include grid-column(12);
      text-align: center;
      
    @include grid-media($for-phone-only) {
      width: 100%;
    }      
      
      
      > li {

        &.icon {
          display: none;
        }
        
        > div {
          display: inline;
        }
        a {
          color: #fff;
        }
        display: inline;
        margin-right: $small-spacing;
        font-size: 1em;
        @include grid-media($for-phone-only) {     
          display: inherit;
          width: 100%;
          text-align: center;
          padding: $small-spacing;
          border: 1px solid black;
          background-color: lighten($blue,5%);
          margin-bottom: $small-spacing;
          &:hover {
            background-color: lighten($blue,15%);
            cursor: pointer;
          }
          a {
            color: #fff;
            font-weight: 600;
            font-size: 1.2em;
          }
        }
      }
      
      @include grid-media($for-phone-only) {
        li.icon {
          display: inherit;
        }
        li.page {
          display: none;
          width: 100%;
          &.responsive {
            display: inline-block;
          }
          padding: $small-spacing / 2;
          font-size: 1em;
          border: 1px solid #333;
          
          a {
            display: inline-block;
            width: 100%;
          }
        }
        li.icon {
          text-align: right;
          padding: $small-spacing / 4;
          padding-right: $small-spacing;
        }
        li {
          margin: 0;
          padding: $small-spacing;
        }
      }      
    }

    ul.sub-pages {
      
      @include grid-media($for-phone-only) {
        display: none;
      }
      
      border: 2px solid lighten($blue,45%);
      border-top: 2px solid $blue;
      display: inline-block;
      padding: $small-spacing;

      li {
        text-align: left;
        
        font-size: 0.9em;
        
        &.drop-header {
          font-size: 1.3em;
        }        
        
        a {
          color: #000;
        }
      }
    }
  
  }

  
}