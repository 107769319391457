.related-slider {
  margin-top: $base-spacing;
  width: 600px;
  
  .slick-slider {
    
    .slick-list {
      margin-left: 20px;
    }
  
    button {
      &:before {
        color: #000;
      }
    }
  
  }
  
  .icon-container {
    position: relative;
    .size-icon {
      display: inline-block;
      color: #000;
      background-color: #ccc;
      padding: 1px 3px;
      font-size: 0.8em;
      position: absolute;
      border: 1px solid #aaa;
      right: 10px;
      top: 5px;
      border-radius: 3px;
      
    }
  }
  
  .item-sku {
    text-align: center;
  }
  
}