.edit-account {
  .customer-info, .user-info, .rep-info {
    margin-bottom: $base-spacing;
    > div, form > div {
      @include grid-column(1 of 1);
    }
    
    .actions {
      display: none;
      
    }
    
  }
  
  .shipping-info {
    width: 100%;
    display: inline-block;
    margin-bottom: $base-spacing;
    > div {
      @include grid-column(1 of 1);
    }
    
  }
  
  .contact-info {
    display: inline-block;
    width: 100%;
    > div {
      @include grid-column(1 of 2);
    }
  }
  
  .csz {
    div {
      @include grid-column(1 of 3);
    }
  }
}