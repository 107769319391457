.login-form {
  
  max-width: 600px;

  padding: $base-spacing;
  
  input {
    @include grid-column(12);
  }
  
  .login-failed {
     @include grid-column(12);
     color: $red;
     padding: $small-spacing 0px;
  }
  
  .standard-buttons {
    @include grid-column(12);
    margin-bottom: $base-spacing;
  }

  @include grid-media($for-phone-only) {
    min-width: 300px;
    input {
      @include grid-column(12);
    }
    button, a {
      @include grid-column(12);
    }
    
    a {
      margin-top: $base-spacing;
    }
  }
}