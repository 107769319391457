.full-screen {
  width: 90%;
  height: 90%;
}

.youtube-video {
  text-align: center;
}

.show-page {
  
  div.pages {
  div.page {
    text-align: center;
    .title {
      font-size: 1em;
      display: inline-block;
      height: 6em;
    }
    
    @include grid-column(6);
    
    @include grid-media($for-tablet-landscape-up) {
      @include grid-column(4);
    }

    @include grid-media($for-desktop-up) {
      @include grid-column(3);
    }

    @include grid-media($for-big-desktop-up) {
      @include grid-column(2);
    }
  }

  }
  
  .page-block {
    h1 {
      font-size: 3em;
      margin-left: 0.5em;
      margin-bottom: 0em;
    }
    
    .anchor {
      color: #00a1dd;
    }
    
  }
  
  .slick-slider {
    &.purple {
      background-color: #A3238E;
    }
    
    &.orange {
      background-color: #F15A22;
    }

    &.teal {
      background-color: #1A96A9;
    }

    &.green {
      background-color: #008A52;
    }    
  }
  
  .slick-slide {

    margin: $base-spacing;
    background-color: #fff;
    img.desktop {
      @include grid-media($for-phone-only) {
        display: none;
      }
    }

    img.mobile {
      @include grid-media($for-tablet-portrait-up) {
        display: none;
      }
    }
    
  }  
  
  .links-block {
    @include grid-column(12);
    text-align: center;
    display: inline-block;
    padding-top: $base-spacing;
    > div {
      display: inline-block;
      background-color: #fff;
    }
    
    &.purple {
      background-color: #A3238E;
    }
    
    &.orange {
      background-color: #F15A22;
    }

    &.teal {
      background-color: #1A96A9;
    }

    &.green {
      background-color: #008A52;
    }


    img {
      width: 100%;
    }

    @include grid-media($for-tablet-portrait-up) {
    
      .block-link-, .block-link-6 {
        @include grid-column(2);
        margin-bottom: $small-spacing;
      }
      .block-link-4 {
        @include grid-column(3);
        margin-bottom: $small-spacing;
        .anchor {
          color: #00a1dd;
        }
      }
      .block-link-3 {
        @include grid-column(4);
        margin-bottom: $small-spacing;
      }
      .block-link-2 {
        @include grid-column(6);
        margin-bottom: $small-spacing;
      }
      .block-link-1 {
        @include grid-column(12);
        margin-bottom: $small-spacing;
      }
      
    }
    
    @include grid-media($for-phone-only) {
      .block-link-3 {
        float: left;
        width: calc(50% - 5px);
        margin-bottom: 3px;
        &:nth-of-type(even) {
          margin-left: 10px;
        }
      }
      
    }

  }
  
  .page-body {
    margin-top: $base-spacing;
  }

  // ul {
  //   text-align: center;
  //   display: inline-block;
  //   li {
  //     white-space: nowrap;
  //     display: inline;
  //     margin-right: $base-spacing * 2;
  //     line-height: 3em;
  //     font-size: 1.3em;    
  //     @include grid-media($for-phone-only) {      
  //       display: inherit;
  //       @include grid-column(12);
  //       text-align: center;
  //       font-size: 1em;
  //       border: 1px solid black;
  //       background-color: lighten($blue,5%);
        
  //       &:hover {
  //         background-color: lighten($blue,15%);
  //         cursor: pointer;
  //       }
  //       a {
  //         color: #fff;
  //         font-weight: 600;
  //         font-size: 1.2em;
  //       }
  //     }
  //   }
  // }
  
  // ul.slick-dots {
  //   li {
  //     position: relative;
  //     display: inline-block;
  //     width: 20px;
  //     height: 20px;
  //     margin: 0 5px;
  //     padding: 0;
  //     cursor: pointer;     
  //   }
  // }  
  
  div.heading {

    padding: $small-spacing / 2 $small-spacing;
    
    &.h1 {
      font-size: 4em;
    }
    &.h2 {
      font-size: 3em;
    }
    &.h3 {
      font-size: 2em;
    }
    &.h4 {
    }    
    &.h5 {
      font-size: 1.5em;
    }
    
    &.green {
      color: #fff;
      background-color: $green;
    }

    &.red {
      color: #fff;
      background-color: $red;
    }
    
    &.blue {
      color: #fff;
      background-color: $blue;      
    }


  }
  
}