$neat-grid: (
  columns: 12,
  gutter: 20px,
);


$small-screen: 415px;
$medium-screen: 737px;
$large-screen: 900px;

$smallest-screen: new-breakpoint(max-width $small-screen - 1px 6);
$small-screen-up: new-breakpoint(min-width $small-screen max-width $medium-screen - 1px 8);

// Use 8 columns when screen is >600px wide
$medium-screen-up: new-breakpoint(min-width $medium-screen 10);

// Use 12 columns when screen is >900px wide
$large-screen-up: new-breakpoint(min-width $large-screen 12);