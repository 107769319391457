.collection-item {
  margin-top: $base-spacing;
  border: 1px solid #ddd;
  min-height: 490px;
  
  .image, .title {
    text-align: center;
  }
  
  .map-agreement { 
    .notice {
      font-size: 1.5em;
      text-align: center;
      line-height: 1em;
      margin-bottom: $small-spacing;
    }
    
    .info {
      font-size: 0.7em;
      text-align: center;
    }
  }
  
  .preorder-now {
    text-align: center;
    color: #fff;
    background-color: $red;
    position: relative;
    width: 100%;
    margin-bottom: -36px;
    font-size: 1.2em;
    
  }
  
  .collapse-collection {
    text-align: center;
    color: #fff;
    background-color: $red;
    position: relative;
    width: 100%;
    margin-bottom: -36px;
    font-size: 0.8em;
  }
  
  &.alert {
    min-height: 500px;
  }
  
  .details {
    padding: 2.5em $base-spacing $small-spacing ;
  }
  
  .availability {
    text-align: center;
  }
  
  .image {
    transition: transform 0.15s;
    // img {
    //   max-height: 200px;
    // }
    margin-bottom: $base-spacing;
    &:hover {
      transform: scale(1.2);
    }
  }
  
  .inners {
    height: 1em;
    font-size: 0.8em;
  }
  
  .category {
    text-align: center;
    font-size: 1.3em;
    background-color: lighten($blue,20%);
    color: #fff;
    
  }
  
  .title {
    height: 5.4em;
    line-height: 1.1em;
  }
  
  .sku, .alert-quantity {
    font-weight: bold;
    font-size: 0.9em;
    width: 50%;
    float: left;
  }
  .price, .alert-date {
    width: 50%;
    font-size: 0.9em;
    float: right;
    text-align: right;
  }
  
  .out-of-stock {
    color: $light-gray;
    min-height: 56px;
  }
  
  .cart-actions, .out-of-stock {
    display: inline-block;
    
  }
  
  .cart-actions {
    
    display: inline-block;
    @include grid-collapse;
    
    .quantity-field {
      @include grid-column(4);
    }
    
    .cart-button {
      text-align: right;
      @include grid-column(8);
    }
    
    @include grid-media($for-phone-only) {
      .quantity-field, .cart-button {
        @include grid-column(12);
        font-size: 0.9em;
        text-align: center;
      }
    }
    
    
  
  }
  
  @include grid-media($for-phone-only) {
    
    min-height: 390px;
    display: inline-block;
    width: 100%;
    
    .details {
      padding: $small-spacing;
      text-align: center;
    }
    
    .title {
      font-size: 0.8em;
      height: 6em;
    }
    .sku, .price {
      text-align: center;
      @include grid-column(12);
    }
    
    .price {
      font-size: 0.9em;
    }
  }

}