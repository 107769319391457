.show-order {
  
  .order-info, .shipping-info, .billing-info {
    display: inline-block;
    @include grid-column(4);
  }
  
  background-color: #fff;
  .order-items {
  }
  
  .order-items {

    margin-bottom: $base-spacing;
    
    padding-top: $base-spacing;
    @include grid-column(12);
    
    thead tr {
      background-color: $blue;
      color: white;
      font-size: 1.2em;
    }
    
    .description {
      width: 24em;
    }
    
    th {
      padding: 10px;
    }
    
    td {
      padding: 5px 10px;
    }
    
    img {
      height: 50px;
    }
      
  }
  
}