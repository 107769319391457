@charset "UTF-8";

/// Performs gamma correction on a single color channel.
///
/// Note that Sass does not have a `pow()` function, so the calculation
/// is approximate.
///
/// @argument {number (0-1)} $channel
///
/// @return {number (0-1)}
///
/// @access private

@function _gamma($channel) {
  @if $channel < 0.03928 {
    @return $channel / 12.92;
  } @else {
    $c: ($channel + 0.055) / 1.055;
    @return (133 * $c * $c * $c + 155 * $c * $c) / 288;
  }
}
