.show-item {

  @include grid-media($grid-small) {
    .quantity-field, .cart-button {
      @include grid-column(6);
    }
    
    .price {
      @include grid-column(12);
    }    
  }
}