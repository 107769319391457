.main, .login-form, .show-item, .address-validation {
  #{$all-buttons} {
  appearance: none;
  background-color: $action-color;
  border: 0;
  border-radius: $base-border-radius;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: $base-font-family;
  font-size: $base-font-size;
  -webkit-font-smoothing: antialiased;
  font-weight: 600;
  line-height: 1;
  padding: $small-spacing $base-spacing;
  text-align: center;
  text-decoration: none;
  transition: background-color $base-duration $base-timing;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;

  &.slim {
    padding: $small-spacing $small-spacing;
  }

  &:hover,
  &:focus {
    background-color: shade($action-color, 20%);
    color: #fff;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;

    &:hover {
      background-color: $action-color;
    }
  }
}
}


button.short, button.issue, button.wrong-item, button.delete {
  background-color: darken($red,20%) !important;
  &:hover,
  &:focus {
    background-color: shade($red, 10%);
    color: #fff;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;

    &:hover {
      background-color: $red;
    }
  }
  
}

button.special-order {
  background-color: $button-special-order !important;
  padding: 0.75em 0.75em !important;
}

button.pre-order {
  background-color: $button-pre-order !important;
  padding: 0.75em 0.75em !important;
}

button.notify-me {
  background-color: $button-notify-me !important;
}

.success {
  background-color: $green;
  &:hover,
  &:focus {
    background-color: shade($green, 20%);
    color: #fff;
  }  
}

.split, .backordered, .damaged {
  background-color: $orange;
  &:hover,
  &:focus {
    background-color: shade($orange, 20%);
    color: #fff;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;

    &:hover {
      background-color: $orange;
    }
  }
  
}

.disabled {
  background-color: $medium-gray;
  &:hover,
  &:focus {
    background-color: shade($medium-gray, 20%);
    color: #fff;
  }
}

.unscannable, .new-box-tag, .under-picked, .overage {
  background-color: $dark-red;
  &:hover,
  &:focus {
    background-color: shade($dark-red, 20%);
    color: #fff;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;

    &:hover {
      background-color: $dark-red;
    }
  }
  
}

