.item-alerts {
  .item-image {
    width: 80px;
  }
  .sku {
    width: 160px;
  }
  .title {
   
  }
  .qty {
    width: 80px;
  }
  .date {
   width: 150px;
  }
  .actions {
   width: 300px;
  }
}