.show-cart {
  
  .prop65-warn {
    font-size: 8pt;
    img {
      width: 12px;
    }
  }  
  
  .discount-freight-notice {
    display: inline-block;
  }
  
  .image {
    width: 70px;
  }
  
  img {
    width: 50px;
  }
  
  .clear-promo {
    font-size: 0.8em;
  }
  
  .sku {
    width: 30em;
  }
  
  .package-count {
    font-size: 0.8em;
    color: #555;
  }
  
  .hazmat {
    font-size: 0.7em;
    img {
      width: 16px;
    }
  }
  
  .inflate {
    text-align: right;
  }
  
  .quantity {
    text-align: right;
    width: 5em;
  }
  
  input.quantity {
    text-align: right;
    width: 5em;
  }
  
  .presale, .sales-multiple {
    margin-top: -0.75em;
    font-size: 0.7em;
    color: #aaa;
  }
  
  .quick-entry, .promo-entry, .action-buttons {
    margin-top: $base-spacing;
  }
  
  .promo {
    padding-left: $base-spacing;
  }

  @include grid-media($for-phone-only) {
    th {
      font-size: 0.7em;
    }
    td {
      font-size: 0.9em;
    }
    input.quantity {
      width: 3em;
    }
    .sku {
      width: 8em;
      padding-left: 0.5em;
    }
  }
  
}