.show-item {
  
  .preorder {
    margin-top: $base-spacing;
    display: inline-block;
  }
  
  .price {
    text-align: right;
    padding-right: $small-spacing;
    @include grid-column(12);
  }

  .quantity-field {
    @include grid-column(4);
  }
  
  .cart-button {
    text-align: right;
    
  }
  
  .image {
    text-align: center;
  }  
  
  .pdf-assets {
    @include grid-column(12);
  }
  
  .asset {
    margin-top: $base-spacing;
    margin-bottom: $base-spacing;
    img {
      max-height: 200px;
    }
    text-align: center;
    max-height: 230px;
    @include grid-column(3);
  }
  
  .map-agreement { 
    .notice {
      font-size: 1.5em;
      text-align: center;
      line-height: 1em;
      margin-bottom: $small-spacing;
    }
    
    .info {
      font-size: 0.7em;
      text-align: center;
    }
  }
  
  .prop65-warn {
    font-size: 10pt;
  }
  
  @include grid-media($for-phone-only) {
    h1 {
      font-size: 0.9em;
    }
    
    .quantity-field, .cart-button {
      @include grid-column(12);
      input, button {
        @include grid-column(12);
      }
    }

    .sku, .upc, .description {
      font-size: 0.9em;
    }
    img {
      max-height: 200px;
    }
    
    .asset {
      max-height: 130px;
      img {
        max-height: 120px;
      }
      @include grid-column(6);
    }

  }
  


  .out-of-stock {
    color: $light-gray;
  }
  min-width: 320px;
  display: inline-block;
  
  table {
    @include grid-column(12);
  }
  
  td, th {
    text-align: right;
    padding: 5px $small-spacing;
    border: none;
    @include grid-media($for-phone-only) {
      padding: 0px $small-spacing;
    }
    
  }
  
  
}