// Typography
//$base-font-family: $font-stack-system;
$base-font-family: 'Rubik', sans-serif;
$heading-font-family: $base-font-family;

// Font Sizes
$base-font-size: 1em;

// Line height
$base-line-height: 1.5;
$heading-line-height: 1.2;

// Other Sizes
$base-border-radius: 3px;
$base-spacing: $base-line-height * 1em;
$small-spacing: $base-spacing / 2;
$base-z-index: 0;

// Colors
$blue: #2478CC;
$light-blue: #3A9A28;
$yellow: #FFF275;
$orange: #FF8C42;
$red: #FF3C38;
$green: darken(#57CE40,15%);
$dark-red: #A23E48;
$dark-gray: #333;
$medium-gray: #999;
$light-gray: #ddd;
$white: #fff;
$lb: #00a1dd;

// Font Colors
$base-font-color: $dark-gray;
$action-color: $green;

$button-add-to-cart: #008542;
$button-pre-order: #2478CC;
$button-notify-me: #a3238e;
$button-special-order: #f7941d;
$button-out-of-stock: #ed1c24;




// Border
$base-border-color: $light-gray;
$base-border: 1px solid $base-border-color;

// Background Colors
$base-background-color: #fff;
$secondary-background-color: tint($base-border-color, 75%);

// Forms
$form-box-shadow: inset 0 1px 3px rgba(#000, 0.06);
$form-box-shadow-focus: $form-box-shadow, 0 0 5px adjust-color($action-color, $lightness: -5%, $alpha: -0.3);

// Animations
$base-duration: 150ms;
$base-timing: ease;
