.address-validation {
  
  div {
    
    @include grid-column(1 of 2); 
    width: 300px;
    font-size: 1.3em;


    @include grid-media($grid-small) {
      @include grid-column(12);
      font-size: 1em;
      width: 220px;
      margin-bottom: $base-spacing; 
    }
    
    
  }
}

.checkout {
  .shipping-info, .billing-info {
    display: inline-block;
    @include grid-column(6);
    
    
    @include grid-media($grid-small) {
      @include grid-column(12);
    }
  
    
    .fields {
      display: inline-block;
    }
    
    .input, .select {
      @include grid-column(12);
    }
    
    .csz {
      @include grid-column(1 of 3);
      @include grid-media($grid-small) {
        @include grid-column(12);
      }
  
    }
  }
  
  .item-disabled {
      color: gray;
      pointer-events: none;
      cursor: not-allowed;
  }  
  
  .payment-help {
    display: inline-block;
  }
  
  .service-info, .bill-info, .comments, .prop-handling {
    .fields {
      @include grid-collapse;
      .input, .select {
        @include grid-column(12);
      }    
    }
    
  }
  
  .notice {
    background-color: #d4f2ce;
    display: block;
    margin: 10px;
    position: relative;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #ccc;
    padding: 10px;    
  }
  
}